import { filter } from 'ramda';
import dayjs from 'dayjs';

// (via Kenneth.Keating@doj.ca.gov)
// The code is available for use for 480 days after the repeal date,
// then it is no longer available.  This allows for the code to be accepted
// by the system until April 1st of the following year which is the last
// day to submit the previous year’s data.

export default (today: string | dayjs.Dayjs) =>
  filter((oc) => {
    const { Repealed, OffenseRepealed } = oc;
    const repeal_date = Repealed ?? OffenseRepealed;
    // 480days after OffenseRepealed it is no longer valid
    return repeal_date ? dayjs(today) < dayjs(repeal_date).add(480, 'day') : true;
  }, window.offenseCodes ?? []);

// DOJ Data dictionary 3.1.4_0920204 updated the requirement for Suspicion offense code
// to be mandatory when Reason for stop is "Reasonable suspicion"
// This requirement officially takes effect for stops on/after 1/1/2025.
// However, we use November 4th, 2024, as the cutoff date for enforcement
// so officers are not required to update previous forms.
const SUSPICION_REQUIRED_DATE = '2025-01-01';
const SUSPICION_REQUIRED_CUTOFF_DATE = '2024-11-18';

export function isSuspicionCodeRequired(stopDate: string): boolean {
  return isDateAfter(stopDate, SUSPICION_REQUIRED_DATE);
}

export function isSuspicionCodeRequiredWithCutoffDate(stopDate: string): boolean {
  return isDateAfter(stopDate, SUSPICION_REQUIRED_CUTOFF_DATE);
}

function isDateAfter(date: string, comparisonDate: string): boolean {
  if (!dayjs(date).isValid()) {
    throw new Error('Invalid date format');
  }

  return dayjs(date).isAfter(comparisonDate);
}
