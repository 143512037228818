import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { Props, connector } from './props';
import './RipaStopForm.scss';

const RipaStopForm = ({ typeOfStop, stopTypes, setTypeOfStop, breakpoint, numberOfPeople, setStoppedPassenger, setStoppedInsideResidence, actionTaken, toggleActionTaken }: Props) => {
  const stopTypesVal = stopTypes?.TypeOfStop?.possibleValues;

  const setTypeOfStopWithPersonUpdate = (value: string) => {
    if (value !== stopTypesVal?.Vehicular.value) {
      // If Type of Stop is not Vehicular, set all StoppedPassenger values to false
      for (let subloopIndex = 0; subloopIndex < numberOfPeople; subloopIndex += 1) {
        setStoppedPassenger(false, subloopIndex);
        if (actionTaken[subloopIndex].includes(stopTypes?.NonForceActionsTaken?.possibleValues.IDofPassenger.value)) {
          toggleActionTaken({ personIndex: subloopIndex, value: stopTypes?.NonForceActionsTaken?.possibleValues.IDofPassenger.value })
        }
        if (actionTaken[subloopIndex].includes(stopTypes?.NonForceActionsTaken?.possibleValues.RanNameOfPassenger.value)) {
          toggleActionTaken({ personIndex: subloopIndex, value: stopTypes?.NonForceActionsTaken?.possibleValues.RanNameOfPassenger.value })
        }
      }
    }
    if (value !== stopTypesVal?.Pedestrian.value) {
      // If Type of Stop is not Pedestrian, set all StoppedInsideResidence values to false
      for (let subloopIndex = 0; subloopIndex < numberOfPeople; subloopIndex += 1) {
        setStoppedInsideResidence(false, subloopIndex);
      }
    }
    setTypeOfStop(value);
  }

  return (
    <>
      <div className="ripa-stop-form" data-testid="ripa-stop-form">
        <div className="ripa-stop-form__title">What was the type of stop?</div>
        <div className="ripa-stop-form__stop-type">
          <FormControl variant="outlined" className="ripa-stop-form__select">
            {stopTypesVal &&
              <Select
                data-testid="ripa-type-of-stop-select-dropdown"
                displayEmpty={true}
                renderValue={value => value === '' ? 'Select type of stop...' : value}
                value={typeOfStop}
                onChange={({ target: { value } }: any) => setTypeOfStopWithPersonUpdate(value)}
                inputProps={{ 'data-testid': 'ripa-type-of-stop-dropdown' }}
                native={breakpoint === 'xs'}
              >
                {breakpoint === 'xs' && <option disabled value="">Select Type of Stop...</option>}
                {Object.keys(stopTypesVal).map((type: string, i) => {
                  const CompName = breakpoint === 'xs' ? 'option' : MenuItem;
                  return (
                    <CompName value={stopTypesVal[type].value} key={i} data-testid={stopTypesVal[type].value.toLowerCase().replace(' ', '-')}>
                      {stopTypesVal[type].value ? stopTypesVal[type].value.concat(' Stop') : ''}
                    </CompName>
                  )
                })}
              </Select>
            }
          </FormControl>
        </div>
      </div>
    </>
  );
};

const Helper = () => (
  <div className="ripa-form-container__helper-box">
    <div className="material-icons">
      help
    </div>
    <div className="ripa-form-container__helper-box-text">
      Pedestrian Stop includes all others, e.g., stops in a residence, passengers on a bus or train, skateboarders, etc.
    </div>
  </div>
)

RipaStopForm.helper = Helper

export default connector(RipaStopForm);
