function normalizeString(str: string): string {
  return str.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '');
}

function findCity(input: string): string {
  if (!window.cities || !Array.isArray(window.cities)) {
    console.error('window.cities is not defined or not an array');
    return '';
  }

  const formattedQuery = normalizeString(input);

  const result = window.cities.find((city: { City: string }) => {
    const formattedCity = normalizeString(city.City);
    return formattedCity.includes(formattedQuery);
  });

  return result ? result.City : '';
}

export { findCity };
